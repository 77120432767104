<template>
  <div
    class="bg-gray-50"
  >
    <div v-if="state === states.READY">
      <div
        v-if="organisationLogo"
        class="p-6 text-center"
        :style="organisationStyle"
      >
        <img
          :src="organisationLogo"
          :alt="job.organisation.name"
        />
      </div>
      <div class="w-full max-w-screen-xl mx-auto py-8 sm:py-16 px-4 rounded-lg">
        <div class="md:flex bg-white shadow rounded-lg">
          <div class="md:w-2/3 p-6">
            <p class="text-center text-2xl my-6">
              You’re signing up to take tests for {{ job.organisation.name }}
            </p>
            <CandidateSignUp
              :job="job"
            />
          </div>
          <div class="md:w-1/3">
            <div class="bg-gray-50 bg-honeycomb text-gray-800 p-4 text-center flex flex-col items-center justify-center rounded-tr-lg rounded-br-lg rounded-bl-lg sm:rounded-br-none h-full w-full">
              <div
                class="xl:w-340 text-center flex flex-col justify-center px-4 xl:px-8"
              >
                <div
                  class="w-full z-20 xl:max-w-sm text-center mx-auto xl:relative"
                >
                  <h2
                    class="inline-flex items-center text-gray-800 leading-tight relative px-2 z-20 bg-gray-200 text-center mx-auto text-sm uppercase tracking-wider font-medium"
                  >
                    <span class="mt-1">Need Help</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5 ml-1"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </h2>

                  <div
                    class="flex flex-wrap border border-gray-400 p-6 pt-8 relative rounded"
                    style="bottom: 0.75rem;"
                  >
                    <div class="w-full text-sm text-gray-800 flex">
                      <ul class="flex-1 text-left">
                        <li
                          v-for="helpLink in helpLinks"
                          :key="helpLink.name"
                          class="mb-6 lg:last:mb-0"
                        >
                          <a
                            :href="helpLink.link"
                            target="_blank"
                            class="flex items-center font-medium hover:text-secondary duration-150 ease-out"
                          >
                            <span class="mr-1">{{ helpLink.name }}</span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-4 w-4 text-gray-600"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                              <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
                            </svg>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else-if="state === states.ERROR"
      class="my-12"
    >
      <p>
        Sorry, an error occured
      </p>
      <p class="errorMessage">
        {{ errorMessage }}
      </p>
    </div>
    <div
      v-else-if="state === states.LOADING"
      class="flex-grow my-12"
    >
      <Loader />
    </div>
  </div>
</template>

<script>
import CandidateSignUp from '@components/Assessments/OpenSignUp/CandidateSignUp'
import Loader from '@components/Loader'

import candidateTokensApi from '@api/candidateTokens'

const states = {
  LOADING: 'loading',
  ERROR: 'error',
  READY: 'ready'
}

export default {
  components: {
    CandidateSignUp,
    Loader
  },

  data() {
    return {
      states,
      state: states.LOADING,

      job: null,
      errorMessage: null,

      helpLinks: [
        {
          link: 'https://support.testcandidates.com/',
          name: 'Support'
        },
        {
          link: '/terms/',
          name: 'Terms & Conditions'
        },
        {
          link: '/privacy-policy/',
          name: 'Privacy Policy'
        },
        {
          link: '/legal/dmca-policy/',
          name: 'DMCA Policy'
        },
        {
          link: '/legal/disability/',
          name: 'Disability Information'
        }
      ]
    }
  },

  computed: {
    /**
     * @return {string}
     */
    organisationStyle() {
      let colour = '#FFFFFF'
      if (this.job.organisation.colour) {
        colour = this.job.organisation.colour
      }
      return 'background-color: ' + colour + ';'
    },

    /**
     * @return {string}
     */
    organisationLogo() {
      if (!this.job.organisation.logo) {
        return
      }

      let logo = this.job.organisation.logo

      let url = []
      url.push('https://res.cloudinary.com')
      url.push(logo.cloudName)
      url.push('image/upload')
      url.push('h_40,w_auto')
      url.push('v' + logo.version)
      url.push(logo.publicId + '.' + logo.format)

      return url.join('/')
    },

    /**
     * @return {string}
     */
    jobId() {
      return this.$route.params.job
    }
  },

  created() {
    if (!this.jobId) {
      this.errorMessage = 'Job is missing'
      this.state = this.states.ERROR
      return
    }

    candidateTokensApi
      .openSignUpJob(this.jobId)
      .then((job) => {
        this.job = job
        this.state = this.states.READY
      })
  }
}
</script>
