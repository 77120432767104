<template>
  <form
    class="py-8 max-w-lg mx-auto"
    novalidate
    @submit.prevent
  >
    <label class="block mt-6 relative">
      <span class="text-gray-800">Full name</span>
      <input
        v-model="name"
        type="name"
        class="form-input mt-1 block w-full bg-gray-100"
        placeholder="Full name"
      >
    </label>

    <label class="block mt-6 relative">
      <span class="text-gray-800">Email address</span>
      <input
        v-model="email"
        type="email"
        class="form-input mt-1 block w-full bg-gray-100"
        placeholder="Email address"
      >
    </label>

    <div
      v-if="emailSent"
      class="mt-4"
    >
      Looks like you’ve already signed up. We’ve sent you an email with a link that’ll sign you in.
    </div>

    <ErrorsBlock v-if="errorMessage">
      {{ errorMessage }}
    </ErrorsBlock>

    <section>
      <BaseButton
        class="mt-8"
        :loading="isProcessing"
        :disabled="!canSubmit"
        :variant="job.organisation.colour ? 'custom' : 'green'"
        :custom-background-colour="job.organisation.colour"
        @click="submit()"
      >
        Sign up
      </BaseButton>
    </section>
  </form>
</template>

<script>
import ErrorsBlock from '@components/ErrorsBlock'

import candidateTokensApi from '@api/candidateTokens'

export default {
  components: {
    ErrorsBlock
  },

  props: {
    job: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      isProcessing: false,
      errorMessage: null,
      emailSent: false,

      email: '',
      name: ''
    }
  },

  computed: {
    /**
     * @return bool
     */
    canSubmit() {
      return this.email.length > 0 &&
        this.name.length > 0 &&
        this.name.includes(' ') &&
        this.email.includes('@')
    }
  },

  mounted() {
    if (this.$route.query.first_name && this.$route.query.surname) {
      this.name = this.$route.query.first_name + ' ' + this.$route.query.surname
    }
    if (this.$route.query.email) {
      this.email = this.$route.query.email
    }
  },

  methods: {
    submit() {
      this.isProcessing = true
      this.errorMessage = null
      this.emailSent = false

      candidateTokensApi
        .openSignUpCandidate(this.job.id, {
          name: this.name,
          email: this.email
        })
        .then((data) => {
          this.isProcessing = false

          if (data.emailSent) {
            this.emailSent = true
            return
          }

          this.$router.push({
            name: 'assessments',
            query: {
              candidate: data.candidate,
              token: data.token,
              sitting: data.sitting
            }
          })
        })
        .catch(error => {
          this.isProcessing = false
          let errorMessage = 'Sorry, an error occured'
          if (error.response.data && error.response.data.errors) {
            errorMessage = error.response.data.errors.join(', ')
          }
          this.errorMessage = errorMessage
          if (!error.response.data) {
            throw error
          }
        })
    }
  }
}
</script>
